
<template>
  <div>  
    <component :is="component">
          <slot/>
    </component>
    
  <div>

  


  <v-flex xs12>
  
    <v-card
        class="mx-auto" :elevation="12"
        color="#FFFFFF"
        style="margin-top:4px"
        max-width="600"
      >
      <v-form
        ref="form"
        v-model="valid" lazy-validation>
          <v-card-title style="background-color:#3e6ba2;color:white;margin-top:16px">
            <v-icon
              large
              left
            >
              mdi-twitter
            </v-icon>
            <span class="title font-weight">Acesso do Cliente</span>
          </v-card-title>

          <div style="width:90%;margin-left:5%; margin-top:16px">
            <v-flex xs12>
              <v-text-field   clearable id="ip_mail"  placeholder="Digite email cadastrado"
                label="EMAIL" large Required :rules="[v => !!v || 'Campo Obrigatório']"
                v-model="data.email"
                prepend-inner-icon="alternate_email"
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field filled clearable color="primary" Required :rules="[v => !!v || 'Campo Obrigatório']" placeholder="Digite senha"
                label="SENHA" large type="password" v-model="data.senha"
                prepend-inner-icon="vpn_key" 
                
              ></v-text-field>
            </v-flex>
          </div>
            <v-alert :value="alert_login" type="error">
            {{msg_login}}
            </v-alert>

            <v-alert :value="alert_login_s" type="success">
              {{msg_login}}
            </v-alert>

            <v-card-actions>
                <v-btn
                    color="#3e6ba2" block class="ma-2 white--text"  
                    @click="handleLogin" large
                    :loading="btn_login_load">
                    <b>Entrar</b>
                    <v-icon dark right style="padding-right:4px"> login </v-icon>
                </v-btn>
            </v-card-actions>
 
            <v-card-actions style="text-align: center;">
              <v-flex xs12 style="width:220px" >
              <v-btn outlined color="red dark-1" style="width:220px"  :loading="btn_rec_load"  text class="ma-2 white--text" 
                  @click="recuperarSenha()" >

                  Recuperar Senha
                            <v-icon dark  right style="padding-right:4px"> lock </v-icon>
                </v-btn>
              
              <v-btn  color="light-green darken-1" style="width:220px"   class="ma-2 white--text" 
                  @click="dialog_cad = true">
                  Cadastrar Acesso
                    <v-icon dark  right style="padding-right:4px" > how_to_reg </v-icon>
                </v-btn>  
              </v-flex>  
            </v-card-actions>

   </v-form>
  </v-card>
  </v-flex>
  

    <div class="text-center" style="text-align: center;">
      <img src="../assets/logo_grupo.png" width="120" height="80" style="margin-top:48px">
  </div>




  <v-dialog
      v-model="dialog_cad"
      persistent
      max-width="500px"
    >
      <v-form
        ref="form_cad"
        v-model="valid"
        lazy-validation>

      <v-card>
        <v-card-title>
          <span class="headline">Cadastro</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
                <v-flex xs12>
                  <v-text-field color="success"
                    label="CPF" Required :rules="[v => !!v || 'Campo Obrigatório']" v-mask="'###.###.###-##'"
                    flat v-model="cadastro.inscricaoAtiva"
                    prepend-inner-icon="perm_identity"
                  ></v-text-field>
                </v-flex>


                <v-flex xs12>
                  <v-text-field color="success" 
                    label="EMAIL" Required :rules="[v => !!v || 'Campo Obrigatório']"
                    flat v-model="cadastro.email"
                    prepend-inner-icon="alternate_email"
                  ></v-text-field>
                </v-flex>

              <v-flex xs12>
                  <v-text-field color="success" Required :rules="[v => !!v || 'Campo Obrigatório']"
                    label="SENHA" type="password" v-model="cadastro.senha"
                    prepend-inner-icon="vpn_key"
                    flat 
                  ></v-text-field>
                </v-flex>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            flat
            :disabled="btn_cad_load"
            @click="dialog_cad = false"
          >
            Sair
          </v-btn>
          <v-btn
            color="success"
            :loading="btn_cad_load"
            text
            @click="handleCad"
          >
            Cadastrar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>



</div>

</div>

</template>

<style scoped>
.hero {
  background: url('../assets/banner.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 300px;
}

.content{   
    width: 100%;
}

.v-dialog--custom {
  height: 50px;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .v-dialog--custom {
    height: 300px;
  }
}


#footer{    
    position:absolute;
    bottom:0; 
    width:100%;
    margin-top: 10px;
}


</style>

<script>
import User from '../models/user'
import AuthService from '../services/auth.service'
import HeadPrevida from '../layouts/VuebaseLayoutLogin'




  export default {
    name: 'Login',
    data () {
      return {
        token_promotor: '',
        btn_login_load: false,
        btn_cad_load: false,
        alert_type: '',
        alert_login_s:false,
        user: new User('', ''),
        cadastro: {
              inscricaoAtiva : '',
              email: '',
              senha: '',
              referenciaContrato: ''
        },
        recuperar: {
              email: ''
        },
        loading: false,
        popup_sorteio: false,
        dialog_cad: false,
        alert_login: false,
        msg_login: '',
        message: '',
        btn_rec_load: false,
        chip2: false,
        expandOnHover: false,
        data: {  
              email: '',
              senha: '',
              ip: '',
              host: '',
              tipoHost: '3',
              versao: '2.3',
        }
      }
    },
     components: {
            HeadPrevida
      },


    computed: {

      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
        !this.$v.name.required && errors.push('Name is required.')
        return errors
      },

      
      loggedIn () {
       // return this.$store.state.auth.status.loggedIn
      },
      component() {
                return HeadPrevida
            }
    },
    
    mounted(){
      this.data.email = ''
      this.data.senha = ''
      //const urlParams = new URLSearchParams(window.location.search);
      //this.token_promotor = urlParams.get("token");
      //const request = require('request')
      const urlParams = new URLSearchParams(window.location.search);
      this.token_promotor = urlParams.get("token");


    
    },
    created () {
      if (this.loggedIn) {
       // this.$router.push('/home')
      }
    },
    methods: {
      handleLogin() { 


        
        if (this.$refs.form.validate()) {
          this.btn_login_load = true
          this.msg_login = "";

                //sessionStorage.setItem('token', undefined)

          AuthService.login(this.data).then(response => {
              if(response.Status === 0){
                sessionStorage.setItem('user', JSON.stringify(response))
                this.$store.commit('setListaContratos', undefined)
                sessionStorage.setItem('responder_digital', null)
                sessionStorage.setItem('token', this.token_promotor)

                this.$router.push('/home')
              }
              if(response.Status === 2){
                this.alert_login = true
                this.alert_login_s = false
                this.msg_login = response.Description
              }

              this.btn_login_load = false

          }).catch(e => {
            
              this.btn_login_load = false
              this.alert_login = true
              this.alert_login_s = false
              this.msg_login = "Ops! Problema na conexão com o servidor. Por favor, aguarde alguns instantes e tente novamente";



        }).finally(() => {
              this.btn_login_load = false

        })
        }
      },

      handleCad () { 

        if (this.$refs.form_cad.validate()) {
         this.btn_cad_load = true
         this.msg_login = '',

          AuthService.cadastro(this.cadastro).then(response => {

                if(response.Status === 1){
                  this.alert_login = true
                  this.alert_login_s = false
                  this.msg_login = response.Description.replace('<br />Caso tenha perdido a senha, utilize a opção', '').replace('Esqueci minha senha', '').replace('para gerar uma nova.', '')
                }

                if(response.Status === 0){
                  this.alert_login_s = true
                  this.alert_login = false
                  this.msg_login = response.Description
                }
            this.btn_cad_load = false    
            this.dialog_cad = false
            this.$refs.form_cad.reset();

          })


        }
      },

       recuperarSenha() { 

        if (this.data.email != "") {
         this.btn_rec_load = true
         this.recuperar.email = this.data.email

          AuthService.recuperarSenha(this.recuperar).then(response => {
       
              this.msg_login = "" 
              if(response.Status === 1){
                  this.alert_login = true
                  this.alert_login_s = false
                  this.msg_login = "Este email não foi localizado em nossos registros"
                }

                if(response.Status === 0){
                  this.alert_login_s = true
                  this.alert_login = false
                  this.msg_login = response.Description

                }


              this.btn_rec_load = false

          }).catch(function (error) {
              this.msg_login = "Por favor, tente novamente mais tarde."
              this.btn_rec_load = false
              //this.dialog_rec = true

          });        
             // this.dialog_rec = true

        }
      },



    },
  }
</script>
